import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
  AccountIcon,
  CURRENCY_OPTIONS,
  CathayIcon,
  Header,
  HeaderPropsType,
  Image,
  OnlineCheckInIcon,
  SearchIcon,
  SelectOption,
} from "@hkexpressairwayslimited/ui";
import { Link } from "@mui/material";
import { agentLogout } from "lib/features/b2b/payment/service";
import { changeLang, getLangLocale } from "modules/common/common/utils/i18n-utils";
import { SUPPORTED_LANGUAGES_WITH_LOCALES } from "modules/common/i18n/conf";
import { useTransContent } from "modules/common/trans-content/transContent";
import { logout } from "modules/features/auth/actions/authAction";
import { selectTokenProfile } from "modules/features/member/selector";
import { changeSelectedCurrency } from "modules/global/currency/service";
import { openSignInPopup, selectCurrencyOptionsShown } from "modules/global/general";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

import { envConfig } from "env";
import { RouterInstance } from "router/router-utils";
import { clearAgentToken, selectAgentProfile, selectEntered } from "store/sessionStorage/slices/b2bSlice";
import { selectCurrency } from "store/sessionStorage/slices/currency";
import { selectSearchingDetail } from "store/sessionStorage/slices/flightBookingSlice";

const HeaderContainer = () => {
  const dispatch = useDispatch();

  const router = useRouter();
  const { t } = useTransContent();
  const tokenProfile = useSelector(selectTokenProfile);
  const agentProfile = useSelector(selectAgentProfile);
  const locale = getLangLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const searchingDetail = useSelector(selectSearchingDetail);
  const changeLanguage = useCallback(
    (lang: string) => {
      changeLang(lang, pathname, searchParams.toString());
    },
    [pathname, searchParams]
  );
  const globalSelectedCurrency = useSelector(selectCurrency);
  const enteredB2b = useSelector(selectEntered);
  const enteredAmPortal = useMemo(() => pathname.includes("full-miles-redemption"), [pathname]);
  const currencyOptionsShown = useSelector(selectCurrencyOptionsShown);

  const onClickLogout = () => {
    if (enteredB2b) {
      agentLogout();
      dispatch(clearAgentToken());
    } else {
      dispatch(logout());
      RouterInstance.replace("/:lang");
    }
  };

  const disableLogout = useMemo(() => {
    const disableLogoutPath = [
      "/flight-booking/add-extras",
      "/flight-booking/seat-selection",
      "/flight-booking/payment",
    ];
    return disableLogoutPath.some((path) => pathname.includes(path));
  }, [pathname]);

  const currencyOptions = useMemo(
    () =>
      CURRENCY_OPTIONS.filter((option) => option.value !== globalSelectedCurrency).map((rate) => ({
        value: rate.value,
        label: t(`web.general.currency.options.${rate.value}`),
        onChange: (option: SelectOption) => {
          // console.log("getCookieDomain", getCookieDomain());
          changeSelectedCurrency(option.value);
        },
      })),
    [globalSelectedCurrency, t]
  );

  const title = useMemo(
    () =>
      ((tokenProfile &&
        t(`web.general.title.options.${tokenProfile?.title ?? "MR"}`, {
          defaultValue: tokenProfile?.title,
        })) as string) || "",
    [t, tokenProfile]
  );

  const otherProps: Partial<HeaderPropsType> = useMemo(
    () => ({
      ...(enteredB2b
        ? {
            variant: Header.Variants.AgentPortal,
            ...(agentProfile
              ? {
                  profile: {
                    fullName: agentProfile.name,
                    credit_account: agentProfile.credit_account,
                  },
                  logoutLabel: t("web.header.booking.logoutLabel"),
                  membershipMenu: [
                    {
                      label: t("web.header.b2b.membershipMenu.agent"),
                      itemProps: {
                        leadingIcon: <OnlineCheckInIcon />,
                        onClick: () => {
                          router.push(`/${getLangLocale()}/b2b/agent-portal`);
                        },
                      },
                    },
                    {
                      label: t("web.header.b2b.membershipMenu.account"),
                      itemProps: {
                        leadingIcon: <AccountIcon />,
                        onClick: () => {
                          router.push(`/${getLangLocale()}/b2b/account`);
                        },
                      },
                    },
                    {
                      label: t("web.header.b2b.membershipMenu.SearchFlight"),
                      itemProps: {
                        leadingIcon: <SearchIcon />,
                        onClick: () => {
                          router.push(`/${getLangLocale()}/b2b/search-flight`);
                        },
                      },
                    },
                  ],
                }
              : {}),
          }
        : {
            loginLabel: t("web.header.booking.loginLabel"),
            logoutLabel: t("web.header.booking.logoutLabel"),
            signInMenu: [
              {
                label: t("web.header.booking.signInMenu.SignUp"),
                itemProps: {
                  onClick: () => {
                    router.push(`/${getLangLocale()}/member/signup`);
                  },
                },
              },
            ].concat(
              !disableLogout
                ? [
                    {
                      label: t("web.header.booking.signInMenu.Login"),
                      itemProps: {
                        onClick: () => {
                          dispatch(openSignInPopup());
                        },
                      },
                    },
                  ]
                : []
            ),
            ...(tokenProfile
              ? {
                  profile: {
                    title,
                    fullName: `${tokenProfile.given_name} ${tokenProfile.family_name}`,
                    lastName: tokenProfile.family_name,
                  },
                  membershipMenu: [
                    {
                      label: t("web.header.booking.membershipMenu.MyBookings"),
                      itemProps: {
                        leadingIcon: <OnlineCheckInIcon />,
                        onClick: () => router.push(`/${getLangLocale()}/manage-my-booking/my-trips`),
                      },
                    },
                    {
                      label: t("web.header.booking.membershipMenu.ProfileUpdate"),
                      itemProps: {
                        leadingIcon: <AccountIcon />,
                        onClick: () => router.push(`/${getLangLocale()}/member/profile`),
                      },
                    },
                    {
                      label: t("web.header.booking.membershipMenu.MyCathayAccount"),
                      itemProps: {
                        leadingIcon: <CathayIcon />,
                        onClick: () => router.push(`/${getLangLocale()}/member/link-cx`),
                      },
                    },
                  ],
                }
              : {}),
            ...(currencyOptionsShown
              ? {
                  currencyOptions,
                  currencyValue: globalSelectedCurrency,
                }
              : {}),
          }),
    }),
    [
      enteredB2b,
      agentProfile,
      t,
      disableLogout,
      tokenProfile,
      title,
      currencyOptionsShown,
      currencyOptions,
      globalSelectedCurrency,
      router,
      dispatch,
    ]
  );

  const localeOptions = useMemo(
    () =>
      SUPPORTED_LANGUAGES_WITH_LOCALES.map((locale) => ({
        value: locale,
        label: t(`web.general.locale.options.${locale}`),
        onChange: (option: SelectOption) => {
          changeLanguage(option.value);
        },
      })),
    [changeLanguage, t]
  );

  const onMobileAccountClick = useCallback(() => {
    dispatch(openSignInPopup());
  }, [dispatch]);
  const logoUrl = useMemo(() => {
    if (enteredB2b) {
      return agentProfile ? `/${locale}/b2b/agent-portal` : `/${locale}/b2b/login`;
    }
    if (enteredAmPortal) {
      return `/${locale}/full-miles-redemption/booking/search`;
    }
    if (searchingDetail?.homePageUrl && !searchingDetail?.homePageUrl.includes(envConfig.bookingHost)) {
      const OUrl = new URL(searchingDetail?.homePageUrl);
      let path = "";
      const pathnames = OUrl.pathname.split("/");
      if (envConfig.languageList.includes(pathnames[1])) {
        pathnames.splice(1, 1, locale);
        path = pathnames.join("/");
      } else {
        path = `${locale}${OUrl.pathname}`;
      }
      return new URL(path, OUrl.origin).href;
    }
    return `${envConfig.publicWebSiteHost}/${locale}`;
  }, [agentProfile, enteredAmPortal, enteredB2b, searchingDetail?.homePageUrl, locale]);

  return (
    <Header
      logo={{
        header: (
          <Link
            href={logoUrl}
            sx={{
              outlineColor: "#59107d",
            }}
          >
            <Image
              src={t("web.hke.logo") as string}
              alt='logo'
              width={0}
              height={0}
              style={{ width: "auto", height: "100%" }}
            />
          </Link>
        ),
        mobileMenu: (
          <Link href={enteredB2b ? "/b2b/agent-portal" : "/"}>
            <Image
              src={t("web.hke.white.logo") as string}
              alt='logo-white'
              width={0}
              height={0}
              style={{ width: "auto", height: "100%" }}
            />
          </Link>
        ),
      }}
      onMobileAccountClick={onMobileAccountClick}
      onLogout={onClickLogout}
      localeOptions={localeOptions}
      localeValue={locale}
      disableLogout={disableLogout}
      {...otherProps}
    />
  );
};

export default HeaderContainer;
